import { Skeleton, Tabs } from 'antd';
import { orderBy, chunk } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';

import LicenseItem from '@/components/explore/components/licenseItem';
import Select from '@/components/select';
import LoadMoreButton from '@/components/explore/components/loadMoreButton';

import { useCollections } from '@/context/CollectionInfosContext';

import { ContractType } from '@/types/apis';
import type { Collection } from '@/types/apis';
import type { Option } from '@/types/index';

import styles from '@/components/explore/style.module.less';
import FetchTemplate from '@/components/explore/components/fetchTemplate';
import usePagination from '@/hooks/usePagination';

const { TabPane } = Tabs;

const options: Option[] = [
    {
        label: 'By Avg. Price',
        value: 'average_price',
    },
    {
        label: 'By Volume',
        value: 'total_volume',
    },
    {
        label: 'By Sales',
        value: 'total_sales',
    },
];

const Page = () => {
    const [loading, setLoading] = useState(true);
    const router = useRouter();
    const query = router?.query?.q || '';

    const data = useCollections();
    const [updatedData, setUpdatedData] = useState<Collection[] | null>(null);
    const [currentTab, setCurrentTab] = useState(ContractType.License);
    const [currentSortOption, setCurrentSortOption] = useState(options[1]);

    const { isOver, currentData, handlePagination } = usePagination<Collection>(updatedData || [], 10, true);

    const handleCardClick = (item: Collection) => {
        router.push({
            pathname: '/collection.html',
            query: {
                address: item.contractAddress,
                contractType: currentTab,
            },
        });
    };

    const handleTabChange = (key: string) => {
        setCurrentTab(key as ContractType);
    };

    const handleSortOptionsChange = (record: Option) => {
        console.log(record);
        setCurrentSortOption(record);
    };

    const renderCards = () => {
        return (currentData || []).map((item, index) => {
            return (
                <LicenseItem
                    index={index + 1}
                    data={item}
                    key={item.contractAddress}
                    loading={false}
                    onClick={handleCardClick.bind(null, item)}
                />
            );
        });
    };

    //current tab data
    useEffect(() => {
        const curTabData = (data || []).filter((item) => item.contractType == currentTab);
        console.log(curTabData);
        const sortData = orderBy(
            curTabData,
            (item: Collection) => {
                return item.meta.stats[currentSortOption.value];
            },
            'desc'
        );
        console.log(
            'sortData',
            sortData.map((item) => item.meta.stats[currentSortOption.value])
        );
        const queryData = sortData.filter(
            (item) => item.meta.name.toLowerCase().indexOf((query as string)?.toLowerCase() as string) >= 0
        );

        setUpdatedData(queryData);
        return () => {};
    }, [data, currentTab, currentSortOption, query]);

    useEffect(() => {
        if (Array.isArray(data) && data.length > 0) {
            setLoading(false);
        }
    }, [data]);

    return (
        <div className={styles['explore-container']}>
            <Tabs defaultActiveKey={ContractType.License} onChange={handleTabChange}>
                <TabPane tab="License" key={ContractType.License}>
                    <FetchTemplate
                        loading={loading}
                        renderLoading={() => {
                            return (
                                <Skeleton
                                    active
                                    avatar
                                    round
                                    paragraph={{
                                        rows: 2,
                                        width: '100%',
                                    }}
                                ></Skeleton>
                            );
                        }}
                        empty={(currentData || []).length === 0}
                    >
                        {renderCards()}
                    </FetchTemplate>
                </TabPane>
                <TabPane tab="Derivatives" key={ContractType.Derivative}>
                    <FetchTemplate
                        loading={loading}
                        renderLoading={() => {
                            return (
                                <Skeleton
                                    active
                                    avatar
                                    round
                                    paragraph={{
                                        rows: 2,
                                        width: '100%',
                                    }}
                                ></Skeleton>
                            );
                        }}
                        empty={(currentData || []).length === 0}
                    >
                        {renderCards()}
                    </FetchTemplate>
                </TabPane>
            </Tabs>
        </div>
    );
};

export default Page;
