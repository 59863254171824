import { Tabs } from 'antd';
import Banner from './modules/banner';
import Recommend from './modules/recommend';
import LicenseItem from '../explore/components/licenseItem';
import { useState } from 'react';
import { useTimeout } from 'ahooks';
import TabPane from 'antd/lib/tabs/TabPane';
import Contact from './modules/contact';
import Explore from './modules/explore';

const Index = () => {
    const [loading, setLoading] = useState(true);

    useTimeout(() => {
        setLoading(false);
    }, 10);

    return (
        <div className="index-container">
            <Banner />
            <Recommend />

            <div className="license-content">
                <Explore />
            </div>
            <Contact />
        </div>
    );
};

export default Index;
