import MIcon from '@/components/mIcon';
import Link from 'next/link';
import { memo, useMemo, useState } from 'react';
import dynamic from 'next/dynamic';
import { useCollections } from '@/context/CollectionInfosContext';
import { formatAmount } from '@/utils/number-utils';
import { ContractType } from '@/types/apis';
import { useRouter } from 'next/router';
import numbro from 'numbro';
import { useUserInfo } from '@/context/UserInfoContext';
import { useDeepCompareEffect } from 'ahooks';
import { getEthPrice } from '@/utils/index';

const Ballroom = dynamic(() => import('@/components/index/modules/ballroom'), { ssr: false });

const Banner = () => {
    const collections = useCollections();
    const router = useRouter();
    const { userInfo } = useUserInfo();

    const [licenseVol, setLicenseVol] = useState('0.00');
    const [derivativeVol, setDerivativeVol] = useState('0.00');
    const [totalVol, setTotalVol] = useState('0.00');

    useDeepCompareEffect(() => {
        if (collections?.length) {
            getEthPrice()
                .then((price) => {
                    const licenseVol = numbro(
                        (collections
                            ?.filter((item) => item.contractType == ContractType.License)
                            .reduce(function (prev, cur) {
                                return prev + cur?.meta?.stats?.one_day_volume || 0;
                            }, 0) || 0) * price
                    )
                        .format({
                            average: true,
                            mantissa: 2,
                        })
                        .toLocaleUpperCase();
                    setLicenseVol(licenseVol);

                    const derivativeVol = numbro(
                        (collections
                            ?.filter((item) => item.contractType == ContractType.Derivative)
                            .reduce(function (prev, cur) {
                                return prev + cur?.meta?.stats?.one_day_volume || 0;
                            }, 0) || 0) * price
                    )
                        .format({
                            average: true,
                            mantissa: 2,
                        })
                        .toLocaleUpperCase();
                    setDerivativeVol(derivativeVol);

                    const totalVol = numbro(
                        (collections?.reduce(function (prev, cur) {
                            return prev + cur?.meta?.stats?.total_volume || 0;
                        }, 0) || 0) * price
                    )
                        .format({
                            average: true,
                            mantissa: 2,
                        })
                        .toLocaleUpperCase();
                    setTotalVol(totalVol);
                })
                .catch((err) => {
                    console.log('err', err);
                });
        }
    }, [collections]);

    const goProfile = (key: string) => {
        localStorage.setItem('defaultActiveKey', key);
        router.push({
            pathname: '/profile.html',
        });
    };
    return (
        <div className="banner-container">
            <div className="img-wrap">
                <div className="item item-img banner-rocket">
                    <img src={require("@/assets/images/banner_rocket.png")} alt="" />
                </div>
                <div className="item item-img banner-ascard">
                    <img src={require("@/assets/images/banner_ascard.png")} alt="" />
                </div>
                <div className="item item-img banner-astronaut">
                    <img src={require("@/assets/images/banner_astronaut.png")} alt="" />
                </div>
                <div className="item item-img banner-planet">
                    <img src={require("@/assets/images/banner_planet.png")} alt="" />
                </div>
            </div>
            <div className="item">
                {/* @ts-ignore */}
                <Ballroom />
            </div>
            <div className="banner-content-box">
                <div className="row row1">Ori</div>
                <div className="row row2">The world's 1st derivative NFT "Mint & Sell" platform</div>
                <div className="row row3">
                    <p>License NFT contents & rights to creators.</p>
                    <p>Create NFT Derivatives under license. </p>
                </div>
                <div className="row row4">
                    <div className="wrap">
                        <a
                            className="btn left-btn"
                            onClick={() => {
                                goProfile('1');
                            }}
                        >
                            <MIcon name="icon17" />
                            <span className="name">License</span>
                        </a>
                        <a
                            className="btn left-btn"
                            onClick={() => {
                                goProfile('2');
                            }}
                        >
                            <MIcon name="icon18" />
                            <span className="name">Create</span>
                        </a>
                    </div>
                </div>
                <div className="row row5">
                    <div className="wrap">
                        <div className="box box1">
                            <div className="text text1">Derivative TRX VOL</div>
                            <div className="text text2">$ {derivativeVol}</div>
                            <div className="text text3">Last 24h</div>
                        </div>
                        <div className="box box2">
                            <div className="text text1">License TRX VOL</div>
                            <div className="text text2">$ {licenseVol}</div>
                            <div className="text text3">Last 24h</div>
                        </div>
                        <div className="box box3">
                            <div className="text text1">
                                <span>Total VOL</span>
                                {/* <MIcon name="icon14" /> */}
                            </div>
                            <div className="text text2">$ {totalVol}</div>
                            <div className="text text3"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default memo(Banner);
