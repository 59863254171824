import MIcon from '@/components/mIcon';
import { Button, Carousel, Typography } from 'antd';
import { memo, useEffect, useMemo } from 'react';
import { getRecommendationList } from '@/api/index';
import useOriRequest from '@/hooks/useOriRequest';
import { useCollections } from '@/context/CollectionInfosContext';
import { formatAmount } from '@/utils/number-utils';
import defaultIcon from '@/assets/svgs/default_icon.svg';

const { Text } = Typography;

const Recommend = () => {
    const collections = useCollections();
    const { data: recommendationResponse, run } = useOriRequest(getRecommendationList, {
        manual: true,
        cacheKey: 'getRecommendationList',
    });

    const listData = useMemo(() => {
        if (collections && recommendationResponse?.data?.length) {
            return recommendationResponse?.data?.map((item: any) => {
                const derivativeData = collections.find((o) => o.contractAddress === item?.derivative?.contractAddress);
                const licenseData = collections.find((o) => o.contractAddress === item?.license?.contractAddress);
                return { derivativeData, licenseData };
            });
        }
        return [];
    }, [collections, recommendationResponse]);

    useEffect(() => {
        run();
    }, []);

    return (
        <div className="recommend-container">
            <div className="wrapper">
                <div className="hidden">{JSON.stringify(listData, null, 2)}</div>
                <Carousel autoplay={true} effect="fade">
                    {listData.map((item, index) => {
                        return (
                            <div className="carousel-item" key={index} data-index={index}>
                                <div className="hidden">{JSON.stringify(item, null, 2)}</div>
                                <div className="carousel-item-wrap">
                                    <div className="m-recommend-title">
                                        <span>Brilliant</span>
                                        <span>Derivatives</span>
                                    </div>

                                    <div className="item operate">
                                        <div className="row row1">Brilliant</div>
                                        <div className="row row2">Derivatives </div>
                                        <div className="row row3">
                                            <div className="label">License price</div>
                                            <div className="value">
                                                <MIcon name="icon13" />
                                                <span className="amount">
                                                    {formatAmount(item?.licenseData?.meta?.stats?.average_price || 0)}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="row row4">
                                            <div className="label">Derivative price</div>
                                            <div className="value">
                                                <MIcon name="icon13" />
                                                <span className="amount">
                                                    {formatAmount(
                                                        item?.derivativeData?.meta?.stats?.average_price || 0
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="row row5">
                                            <a
                                                href={
                                                    process.env.NEXT_PUBLIC_OPENSEA +
                                                    '/collection/' +
                                                    item?.licenseData?.meta?.slug
                                                }
                                                target="_blank"
                                            >
                                                <Button className="secondary-btn">Get license</Button>
                                            </a>
                                        </div>
                                        <div className="row row6">
                                            <a
                                                href={
                                                    process.env.NEXT_PUBLIC_OPENSEA +
                                                    '/collection/' +
                                                    item?.derivativeData?.meta?.slug
                                                }
                                                target="_blank"
                                            >
                                                <Button className="primary-btn">Get derivative</Button>
                                            </a>
                                        </div>
                                        <div className="row row7">
                                            <div className="p-item"></div>
                                            <div className="p-item"></div>
                                            <div className="p-item"></div>
                                        </div>
                                    </div>
                                    {/* item.derivativeData */}
                                    <a
                                        href={
                                            process.env.NEXT_PUBLIC_OPENSEA +
                                            '/collection/' +
                                            item?.licenseData?.meta?.slug
                                        }
                                        target="_blank"
                                    >
                                        <div className="item nft-item">
                                            <div className="item-wrap">
                                                <div className="cover">
                                                    <img
                                                        src={
                                                            item.derivativeData?.meta?.image_url ||
                                                            item.derivativeData?.meta?.primary_asset_contracts?.[0]
                                                                ?.image_url ||
                                                            defaultIcon
                                                        }
                                                        onError={(e: any) => {
                                                            e.target.src = defaultIcon;
                                                        }}
                                                        // alt={
                                                        //     item.derivativeData?.meta?.primary_asset_contracts?.[0]
                                                        //         ?.description
                                                        // }
                                                    />
                                                    {/* 
                                                        <div className="icon">
                                                            <MIcon name="icon01" />
                                                        </div> 
                                                    */}
                                                </div>
                                                <div className="info">
                                                    {/* <Text className="title" ellipsis={true}>
                                                        {item.derivativeData?.meta?.primary_asset_contracts?.[0]?.name}
                                                    </Text> */}
                                                    <div className="subtitle">
                                                        {/* <Text className="text">Item name_LIC {index + 1}</Text> */}
                                                        <Text className="text" ellipsis={true}>
                                                            {item.derivativeData?.meta?.name ||
                                                                item.derivativeData?.meta?.primary_asset_contracts?.[0]
                                                                    ?.name}
                                                        </Text>
                                                        <MIcon name="icon03" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                    {/* item.licenseData */}
                                    <a
                                        href={
                                            process.env.NEXT_PUBLIC_OPENSEA +
                                            '/collection/' +
                                            item?.licenseData?.meta?.slug
                                        }
                                        target="_blank"
                                    >
                                        <div className="item nft-item">
                                            <div className="item-wrap">
                                                <div className="cover">
                                                    <img
                                                        src={
                                                            item.licenseData?.meta?.image_url ||
                                                            item.licenseData?.meta?.primary_asset_contracts?.[0]
                                                                ?.image_url ||
                                                            defaultIcon
                                                        }
                                                        onError={(e: any) => {
                                                            e.target.src = defaultIcon;
                                                        }}
                                                        // alt={
                                                        //     item.licenseData?.meta?.primary_asset_contracts?.[0]
                                                        //         ?.description
                                                        // }
                                                    />
                                                </div>
                                                <div className="info">
                                                    {/* <Text className="title" ellipsis={true}>
                                                        {item.licenseData?.meta?.primary_asset_contracts?.[0]?.name}
                                                    </Text> */}
                                                    <div className="subtitle">
                                                        {/* <span className="text">Item name_LIC {index + 1}</span> */}
                                                        <Text className="text" ellipsis={true}>
                                                            {item.licenseData?.meta?.name ||
                                                                item.licenseData?.meta?.primary_asset_contracts?.[0]
                                                                    ?.name}
                                                        </Text>
                                                        <MIcon name="icon03" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        );
                    })}
                </Carousel>
            </div>
        </div>
    );
};

export default memo(Recommend);
