import { useBoolean, useRequest, useSetState } from 'ahooks';
import storage from '@/utils/storage';
import md5 from 'md5';
import { isServer } from '@/utils/index';
import { useEffect, useState } from 'react';
import type { Service, Options, Result } from 'ahooks/lib/useRequest/src/types';

/**
 *
 * 劫持 ahooks useRequest run 方法，实现缓存(IndexedDB)（ TODO optimized... ）
 *
 * @param method
 * @param options
 * @returns
 */
export default function useOriRequest<TData extends Record<string, any>, TParams extends any[]>(
    method: Service<TData, TParams>,
    options?: Options<TData, TParams>
): Result<TData, TParams> {
    const [data, setData] = useSetState<TData>({} as TData);

    const [suffix, setSuffx] = useState('');

    const [loading, { setTrue, setFalse }] = useBoolean(true);

    let cacheKey = '';

    if (options?.cacheKey) {
        cacheKey = options.cacheKey;
        options.cacheKey = undefined;
    }

    const request: Result<TData, TParams> = useRequest(method, options);

    const { data: responseData } = request;

    const requestRun = request.run;

    request.run = async (...params) => {
        setTrue();
        if (cacheKey && !isServer()) {
            const suffix = md5(JSON.stringify(params));
            setSuffx(suffix);
            const key = `${cacheKey}_${suffix}`;
            const cacheData: any = await storage.getItem(key);
            if (cacheData) {
                setData(cacheData);
                setFalse();
            }
        }
        return requestRun(...params);
    };

    request.runAsync = () => {
        throw new Error('runAsync is not supported');
    };

    useEffect(() => {
        if (cacheKey && !isServer() && responseData) {
            const key = `${cacheKey}_${suffix}`;
            storage.setItem(key, responseData);
        }
        if (responseData) {
            setData(responseData as TData);
            setFalse();
        }
    }, [responseData, suffix]);

    return {
        ...request,
        data,
        loading,
    };
}
