import { useState } from 'react';
import { Col, Row, Skeleton } from 'antd';
import numbro from 'numbro';
import cs from 'classnames';

import InfoItem from '@/components/infoItem';
import { formatAmount } from '@/utils/number-utils';

import verifyedIcon from '@/assets/svgs/verifyed_icon.svg';
import topArrow from '@/assets/svgs/top_arrow.svg';
import defaultIcon from '@/assets/svgs/default_icon.svg';
import { ContractType } from '@/types/apis';
import type { Collection } from '@/types/apis';

import type { HTMLAttributes } from 'react';

import styles from './style.module.less';

export interface LicenseItem extends HTMLAttributes<HTMLDivElement> {
    index: number;
    data: Collection;
    loading?: boolean;
}

const LicenseItem = ({ data, index, loading = true, ...containerProps }: LicenseItem) => {
    const {
        meta: { primary_asset_contracts, stats, name, image_url },

        contractType,
    } = data;

    const [isActive, setIsActive] = useState(false);

    const arrowClass = cs(`${styles.arrow}`, {
        [styles.active]: !isActive,
        [styles.inactive]: isActive,
    });

    const handleCollapse = (e: MouseEvent) => {
        e.stopPropagation();
        setIsActive(!isActive);
    };

    return (
        <Skeleton
            loading={loading}
            active
            avatar
            round
            paragraph={{
                rows: 1,
                width: '100%',
            }}
        >
            {/* PC */}
            <div className={`${styles['pc-container']} button_hover`} {...containerProps}>
                <div className={styles['license-item']}>
                    <span className={styles.index}>{index}</span>
                    <div className={styles.info}>
                        {/* icon */}
                        <div className={styles['icon-container']}>
                            <img
                                className={styles.icon}
                                src={image_url || primary_asset_contracts?.[0]?.image_url || defaultIcon}
                                alt=""
                                onError={(e: any) => {
                                    e.target.src = defaultIcon;
                                }}
                            />
                            <img className={styles.verify} src={verifyedIcon} alt="" />
                        </div>
                        {/* name container */}
                        <div className={styles['name-container']}>
                            <span className={styles.name}>{name || primary_asset_contracts?.[0]?.name}</span>

                            <span className={styles.collection}>
                                {contractType == ContractType.License ? 'License collection' : 'Derivatives collection'}
                            </span>
                        </div>

                        <div className={styles.infos}>
                            <InfoItem value={`${formatAmount(stats?.total_volume)} Ξ`} label="Vol." />
                            <InfoItem
                                value={`${numbro(stats?.total_sales).format({
                                    average: true,
                                    mantissa: 0,
                                })} Ξ`}
                                label="Sales"
                            />
                            <InfoItem value={`${formatAmount(stats?.average_price)} Ξ`} label="Avg. Price" />
                            <InfoItem
                                value={`${numbro(stats?.num_owners).format({
                                    average: true,
                                    mantissa: 0,
                                })} Ξ`}
                                label="Owners"
                            />
                            <InfoItem
                                value={`${numbro(stats?.count).format({
                                    average: true,
                                    mantissa: 0,
                                })} Ξ`}
                                label="Items"
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* H5 */}

            <div className={styles['h5-container']} {...containerProps}>
                <div className={styles['license-item']}>
                    <span className={styles.index}>{index}</span>
                    <div className={styles.info}>
                        {/* icon */}
                        <div className={styles['icon-container']}>
                            <img
                                className={styles.icon}
                                src={image_url || primary_asset_contracts?.[0]?.image_url || defaultIcon}
                                alt=""
                                onError={(e: any) => {
                                    e.target.src = defaultIcon;
                                }}
                            />
                            <img className={styles.verify} src={verifyedIcon} alt="" />
                        </div>
                        {/* name container */}
                        <div className={styles['name-container']}>
                            <span className={styles.name}>{name || primary_asset_contracts?.[0]?.name}</span>
                            <span className={styles.collection}>
                                {contractType == ContractType.License ? 'License collection' : 'Derivatives collection'}
                            </span>
                        </div>
                    </div>
                </div>
                {/* arrow */}
                <img className={arrowClass} src={topArrow} onClick={handleCollapse} />
                {/* info items */}
                <Row className={styles.infos} gutter={[0, 20]}>
                    <Col span={12}>
                        <InfoItem value={`${formatAmount(stats?.total_volume)} Ξ`} label="Vol." />
                    </Col>
                    <Col span={12}>
                        <InfoItem
                            value={`${numbro(stats?.total_sales).format({
                                average: true,
                                mantissa: 0,
                            })} Ξ`}
                            label="Sales"
                        />
                    </Col>
                    {isActive && (
                        <>
                            <Col span={12}>
                                <InfoItem
                                    value={`${numbro(stats?.num_owners).format({
                                        average: true,
                                        mantissa: 0,
                                    })} Ξ`}
                                    label="Owners"
                                />
                            </Col>
                            <Col span={12}>
                                <InfoItem
                                    value={`${numbro(stats?.count).format({
                                        average: true,
                                        mantissa: 0,
                                    })} Ξ`}
                                    label="Items"
                                />
                            </Col>
                        </>
                    )}
                </Row>
            </div>
        </Skeleton>
    );
};
export default LicenseItem;
